<template>
  <div v-if="isMounted" id="filter" class="ps-filter-template w-100" :class="isRowFilter ? 'd-block' : 'd-flex'">
    <template v-if="!Array.isArray(filterSettings)">
      <div class="filter-container" :style="isRowFilter ? 'display: table-caption;' : ''">
        <FilterFormGroup
              v-for="(item, key) in filterSettings"
            :key="item.refresh_uuid ? key + '-' + item.refresh_uuid : key"
            :filterKey="key"
            :filterSettings="filterSettings"
            :item="item"
            :models="models"
            :getThis="getThis"
            :suggestions="suggestions"
            @search="search"
        >
          <template v-for="key in Object.keys(filterSettings)" v-slot:[`autosuggest-item(${key})`]="{item}">
            <slot :name="`autosuggest-item(${key})`" :item="item"></slot>
          </template>
          <template v-for="key in Object.keys(filterSettings)" v-slot:[`multi-select__selected(${key})`]="{option}">
            <slot :name="`multi-select__selected(${key})`" :option="option"></slot>
          </template>
          <template v-for="key in Object.keys(filterSettings)" v-slot:[`multi-select__text(${key})`]="{option}">
            <slot :name="`multi-select__text(${key})`" :option="option"></slot>
          </template>
        </FilterFormGroup>

        <div class="d-inline-flex" style="gap: 3px;">
          <b-button v-if="showImport" variant="success" @click.prevent="importFile()">Import</b-button>
          <b-button v-if="!hideSearch" variant="primary" @click.prevent="search()">{{ searchButtonText }}</b-button>
          <b-button v-if="showExport" variant="success" @click.prevent="exportToCSV()">CSV Export</b-button>
        </div>
      </div>
    </template>

    <template v-else>
      <div
          v-for="(filterSetting, iFilterSetting) in filterSettings"
          :class="[{ 'row' : Object.values(filterSetting)[0] && Object.values(filterSetting)[0].groupName }]"
          :key="iFilterSetting"
      >
        <template v-if="!Array.isArray(filterSetting) && Object.values(filterSetting)[0]">
          <div v-if="Object.values(filterSetting)[0].groupName" class="col-1 mr-2 mt-2">{{ Object.values(filterSetting)[0].groupName }}</div>
          <div
              :class="{
                'filter-container': iFilterSetting === 0 || Object.values(filterSetting)[0].groupName,
                'border card p-2 col-10':  Object.values(filterSetting)[0].groupName
              }"
              :style="isRowFilter ? (Object.values(filterSetting)[0].groupStyle ? Object.values(filterSetting)[0].groupStyle : 'display: table-caption;') : ''"
          >
            <FilterFormGroup
                v-for="(item, key) in filterSetting"
                :key="item.refresh_uuid ? key + '-' + item.refresh_uuid : key"
                :filterKey="key"
                :filterSettings="filterSetting"
                :item="item"
                :models="models"
                :getThis="getThis"
                :suggestions="suggestions"
                @search="search"
            >
              <template v-slot:[`autosuggest-item(${key})`]="{item}">
                <slot :name="`autosuggest-item(${key})`" :item="item"></slot>
              </template>
            </FilterFormGroup>
          </div>
        </template>

        <template v-else>
          <div v-if="filterSetting[0]" class="col-1 pr-2 mt-2">{{ filterSetting[0].groupName }}</div>
          <div v-if="filterSetting[0]" class="border card p-2 col-11">
            <div v-for="(dynamicField, iDynamicField) in filterSetting.slice(1)" :key="iDynamicField">
              <div v-if="iDynamicField > 0" class="font-weight-bolder mb-1">AND</div>
              <div class="filter-container">
                <FilterFormGroup
                    v-for="(item, key) in dynamicField"
                    :key="item.refresh_uuid ? key + '-' + item.refresh_uuid : key"
                    :filterKey="key"
                    :filterSettings="dynamicField"
                    :item="item"
                    :models="models"
                    :getThis="getThis"
                    :suggestions="suggestions"
                    @search="search"
                >
                  <template v-slot:[`autosuggest-item(${key})`]="{item}">
                    <slot :name="`autosuggest-item(${key})`" :item="item"></slot>
                  </template>
                </FilterFormGroup>
              </div>
            </div>

            <div>
              <b-button @click="$emit('addDynamicField')" variant="info">{{ dynamicFieldButtonAdd }}</b-button>
            </div>
          </div>
        </template>
      </div>

      <div  class="d-inline-flex ml-1" style="height: fit-content; gap: 3px;">
        <b-button v-if="showImport" variant="success" @click.prevent="importFile()">Import</b-button>
        <b-button variant="primary" @click.prevent="search()">{{ searchButtonText }}</b-button>
        <b-button v-if="showExport" variant="success" @click.prevent="exportToCSV()">CSV Export</b-button>
      </div>
    </template>

  </div>
</template>

<script>
import FilterFormGroup from './FilterFormGroup.vue'

export default {
  inheritAttrs: false,
  components: {
    FilterFormGroup
  },
  props: {
    value: {
      type: Object
    },
    filterSettings: {
      type: [Object, Array]
    },
    hideSearch: {
      type: Boolean,
      default: false
    },
    isRowFilter: {
      type: Boolean,
      default: false
    },
    injectActions: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    firstSearch: {
      type: Boolean,
      default: true
    },
    showExport: {
      type: Boolean,
      default: false
    },
    showImport: {
      type: Boolean,
      default: false
    },
    disableDates: {
      type: Array,
      default: () => []
    },
    useQueryUrl: {
      type: Boolean,
      default: true
    },
    searchButtonText: {
      type: String,
      default: 'Search'
    },
    dynamicFieldButtonAdd: {
      type: String,
      default: 'Add Another'
    }
  },
  data () {
    return {
      models: {},
      isMounted: false,
      suggestions: {},
      prevModels: {},
      isReadyToSendActionLog: true
    }
  },
  methods: {
    getValidationState ({ valid = null }) {
      if (!valid) return false
      return null
    },
    search () {
      const isModelsChanged = JSON.stringify(this.models) !== JSON.stringify(this.prevModels)
      if (this.isReadyToSendActionLog) {
        this.sendActionLog(`change filter search ${this.$route.path} with values ${JSON.stringify(this.models)}`)
      }

      if (isModelsChanged) {
        this.$emit('changed')
      }

      if (this.useQueryUrl) {
        this.setParamsFilterToUrl(isModelsChanged)
        this.generateFilterValuesByUrl()
      }

      this.$emit('input', this.models)
      this.$emit('search')

      this.prevModels = JSON.parse(JSON.stringify(this.models))
    },
    getSelectIdentValue (item) {
      if (typeof item.value !== 'undefined') return item.value
      return item.id
    },
    getSelectTextValue (item) {
      if (typeof item.text !== 'undefined') return item.text
      return item.name
    },
    resetSelectField (fieldName) {
      const firstItem = this.filterSettings[fieldName].data[0]
      this.models[fieldName] = this.getSelectIdentValue(firstItem)
      this.refreshLayoutOfField(fieldName)
    },
    refreshLayoutOfField (fieldName) {
      this.filterSettings[fieldName].refresh_uuid = (Math.random() + 1).toString(36).substring(7)
    },
    refreshLayoutOfFieldByKey (key, fieldName) {
      this.filterSettings[key][fieldName].refresh_uuid = (Math.random() + 1).toString(36).substring(7)
    },
    setValue (fieldName, value) {
      this.models[fieldName] = value
      this.refreshLayoutOfField(fieldName)
    },
    setValueByKey (key, fieldName, value) {
      this.models[fieldName] = value
      this.refreshLayoutOfFieldByKey(key, fieldName)
    },
    exportToCSV () {
      this.$emit('input', this.models)
      this.$emit('exportToCSV')
    },
    importFile () {
      this.$emit('input', this.models)
      this.$emit('importFile')
    },
    setParamsFilterToUrl (isModelsChanged) {
      const queryParams = new URLSearchParams(window.location.search)

      for (const key in this.models) {
        const currentValue = this.models[key]
        const currentFilterSetting = Array.isArray(this.filterSettings) ? Object.assign({}, ...this.filterSettings)[key] : this.filterSettings[key]
        if (currentFilterSetting) {
          if (currentFilterSetting.type === 'daterange') {
            if (currentValue.fromDate) {
              queryParams.set(`filter[${  key  }From]`, currentValue.fromDate)
            }
            if (currentValue.toDate) {
              queryParams.set(`filter[${  key  }To]`, currentValue.toDate)
            }
          } else {
            const currentQueryParam = queryParams.get(`filter[${key}]`)

            if (currentValue || (currentValue === '' && currentQueryParam) || currentFilterSetting.type === 'checkbox_yes_no' || currentValue === 0) {
              queryParams.set(`filter[${key}]`, currentValue)
            }

            const appendModel = currentFilterSetting.append && this.models[currentFilterSetting.append]
            if (appendModel) {
              queryParams.set(`filter[${currentFilterSetting.append}]`, appendModel)
            }

            if (currentFilterSetting.options && currentFilterSetting.optionModel) {
              const optionModel = this.models[currentFilterSetting.optionModel]
              if (optionModel) {
                const optionTrackByValues = optionModel.map(option => option[currentFilterSetting.optionTrackBy])
                queryParams.set(`filter[${currentFilterSetting.optionModel}]`, optionTrackByValues.join(','))
              }
            }
          }

        }
      }

      if (isModelsChanged) {
        queryParams.set('page', 1)
      }

      if (queryParams.size > 0) {
        const newUrl = `${window.location.pathname  }?${  queryParams.toString()}`
        // const humanizeUrl = decodeURIComponent(newUrl);
        window.history.replaceState({}, '', newUrl)
      }
    },
    generateFilterValuesByUrl () {
      const queryParams = new URLSearchParams(window.location.search)

      for (const key in this.models) {
        const currentFilterSetting = Array.isArray(this.filterSettings) ? Object.assign({}, ...this.filterSettings)[key] : this.filterSettings[key]

        if (currentFilterSetting) {
          if (currentFilterSetting.type === 'daterange') {
            const currentFromDateQueryParam = queryParams.get(`filter[${  key  }From]`)
            const currentToDateQueryParam = queryParams.get(`filter[${  key  }To]`)

            if (currentFromDateQueryParam && currentToDateQueryParam) {
              this.models[key].fromDate = currentFromDateQueryParam
              this.models[key].toDate = currentToDateQueryParam
            }
          } else if (currentFilterSetting.type === 'checkbox') {
            const currentQueryParam = queryParams.get(`filter[${  key  }]`)
            if (currentQueryParam) {
              this.models[key] = currentQueryParam.split(',')
            } else {
              this.models[key] = []
            }
          } else if (currentFilterSetting.type === 'auto-suggest') {
            const currentQueryParam = queryParams.get(`filter[${  key  }]`)
            if (currentQueryParam) {
              this.models[key] = currentQueryParam
              currentFilterSetting.defaultValue = currentQueryParam

              if (currentFilterSetting.keyText === currentFilterSetting.keyValue) {
                currentFilterSetting.value = currentQueryParam
              }
            }
          } else {
            const currentQueryParam = queryParams.get(`filter[${  key  }]`)
            if (currentQueryParam) {
              this.models[key] = currentQueryParam
            }

            if (currentFilterSetting.append && queryParams.get(`filter[${  currentFilterSetting.append  }]`)) {
              this.models[currentFilterSetting.append] = queryParams.get(`filter[${  currentFilterSetting.append  }]`)
            }
          }
        }
      }
    },
    generateModels () {
      if (Array.isArray(this.filterSettings)) {
        this.filterSettings.forEach(filterSetting => {
          for (const key in filterSetting) {
            this.models[key] = this.$route.query[`filter[${  key  }]`] ?? filterSetting[key].defaultValue
          }
        })
      } else {
        for (const key in this.filterSettings) {
          this.models[key] = this.$route.query[`filter[${  key  }]`] ?? this.filterSettings[key].defaultValue
        }
      }

      this.prevModels = JSON.parse(JSON.stringify(this.models))
    }
  },
  computed: {
    getThis () {
      return this
    }
  },
  created () {
    this.generateModels()
  },
  async mounted () {
    this.isMounted = true

    if (this.firstSearch) {
      this.isReadyToSendActionLog = false
      await this.search()
      this.isReadyToSendActionLog = true
    }
  }
}
</script>
<style lang="scss">
.ps-filter-template {
  margin-bottom: 10px;
}
.ps-filter-template .filter-container {
  display: table;
  width: 100%;
}

.ps-filter-template .filter-container > * {
  clear: none;
  float:left;
  margin-right: 10px;
}

.ps-filter-template .leftx {
  display: flex;
}
.ps-filter-template .leftx li {
  margin-right: 10px;
  font-size: 14px;
}
.ps-filter-template .input-select .vs-select--input {
  max-width: 150px;
}
.ps-filter-template .custom-checkbox {
  margin-top: 5px;
  margin-bottom: 5px;
}
.autosuggest-container {
  display: flex;
  justify-content: center;
  width: 280px;
}

#autosuggest {
  width: 100%;
  display: block;

  .autosuggest__results-item--highlighted {
    background-color: rgba(51, 217, 178,0.2);
  }
  .autosuggest__results-container {
    position: relative;
    z-index: 999;

    .autosuggest__results {
      background-color: #fff;
      padding-top: 1rem;
      border-radius: 0.5rem;
      -webkit-box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
      box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
      position: absolute;
      width: 100%;
      overflow-y: auto;
      max-height: 40vh;

      ul {
        list-style-type: none;
        padding-left: 10px;
      }
    }

    .autosuggest__results-item {
      padding: 5px;
      cursor: pointer;
    }
  }
}

</style>
