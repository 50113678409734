<template>
  <div v-if="!item.hidden">
    <!-- GENERATE INPUT -->
    <b-form-group
        :class="item.class ? item.class : ''"
        v-if="item.type === 'input'"
        :label="item.label ? item.label: ''"
        :label-cols="typeof item['label-cols'] === 'number' ? item['label-cols'] : null"
        :label-cols-sm="typeof item['label-cols-sm'] === 'number' ? item['label-cols-sm'] : null"
        :label-cols-md="typeof item['label-cols-md'] === 'number' ? item['label-cols-md'] : null"
        :label-cols-lg="typeof item['label-cols-lg'] === 'number' ? item['label-cols-lg'] : null"
        :label-cols-xl="typeof item['label-cols-xl'] === 'number' ? item['label-cols-xl'] : null"
        :label-class="item['label-class']"
        :content-cols="typeof item['content-cols'] === 'number' ? item['content-cols'] : null"
        :content-cols-sm="typeof item['content-cols-sm'] === 'number' ? item['content-cols-sm'] : null"
        :content-cols-md="typeof item['content-cols-md'] === 'number' ? item['content-cols-md'] : null"
        :content-cols-lg="typeof item['content-cols-lg'] === 'number' ? item['content-cols-lg'] : null"
        :content-cols-xl="typeof item['content-cols-xl'] === 'number' ? item['content-cols-xl'] : null"
        :style="item.style"
        :disabled="item.isDisabled"
    >
      <b-input-group>
        <template v-if="item.append" #append>
          <div class="text-center margin-auto" v-if="item.appendText">{{ item.appendText }}</div>
          <b-form-input
              v-else
              v-model="models[item.append]"
              :placeholder="item.appendPlaceHolder || ''"
              @keypress.enter.exact="search()"
              @change="item.change ? item.change(models[item.append], getThis) : null;"
              style="border-top-left-radius: 0; border-bottom-left-radius: 0; width: 60px;"
          >
          </b-form-input>
        </template>
        <b-form-input
            v-model="models[filterKey]"
            :placeholder="item.placeHolder"
            @keypress.enter.exact="search()"
            @change="item.change ? item.change(models[filterKey], getThis) : null;"
            :class="{ 'rounded-left' : item.append }"
        ></b-form-input>
      </b-input-group>
    </b-form-group>


    <!-- GENERATE CHECKBOXES -->
    <b-form-group
        v-if="item.type === 'checkbox'"
        :label="item.label"
        :class="item.class ? item.class : ''"
        :label-cols="typeof item['label-cols'] === 'number' ? item['label-cols'] : null"
        :label-cols-sm="typeof item['label-cols-sm'] === 'number' ? item['label-cols-sm'] : null"
        :label-cols-md="typeof item['label-cols-md'] === 'number' ? item['label-cols-md'] : null"
        :label-cols-lg="typeof item['label-cols-lg'] === 'number' ? item['label-cols-lg'] : null"
        :label-cols-xl="typeof item['label-cols-xl'] === 'number' ? item['label-cols-xl'] : null"
        :content-cols="typeof item['content-cols'] === 'number' ? item['content-cols'] : null"
        :content-cols-sm="typeof item['content-cols-sm'] === 'number' ? item['content-cols-sm'] : null"
        :content-cols-md="typeof item['content-cols-md'] === 'number' ? item['content-cols-md'] : null"
        :content-cols-lg="typeof item['content-cols-lg'] === 'number' ? item['content-cols-lg'] : null"
        :content-cols-xl="typeof item['content-cols-xl'] === 'number' ? item['content-cols-xl'] : null"
        :style="item.style"
    >
      <template #label>
        <span v-if="item.htmlLabel" v-html="item.htmlLabel"></span>
        <span v-else>{{ item.label }}</span>
      </template>

      <b-form-checkbox-group
          :key="'checkboxes-' + item.refresh_uuid ? filterKey + '-' + item.refresh_uuid : filterKey"
          id="checkbox-group-2"
          v-model="models[filterKey]"
          name="flavour-2"
          :stacked="item.stacked || false"
      >
        <b-form-checkbox :class="[item.refresh_uuid, {'select-options': itemData.options}]" v-for="(itemData, index) in filterData(item.data)" :key="index" :value="itemData.value" @change="item.change ? item.change(itemData.value, models[filterKey], getThis) : null;">
          {{ itemData.text }}
          <span v-if="itemData.appendHTML" v-html="itemData.appendHTML"></span>
          <b-form-select
              v-if="itemData.options"
              :options="itemData.options"
              :value="getInitialOption(filterKey, itemData)"
              @change="handleSelectChange($event, filterKey, itemData)"
              style="width: 100px;"
          />
        </b-form-checkbox>
      </b-form-checkbox-group>
    </b-form-group>

    <!-- GENERATE CHECKBOXES YES NO -->
    <b-form-checkbox
        v-if="item.type === 'checkbox_yes_no'"
        :class="item.class ? item.class : ''"
        v-model="models[filterKey]"
        @change="item.change ? item.change(models[filterKey], getThis) : null;"
        :style="item.style"
        :disabled="item.isDisabled"
    >
      {{ item.label }}
    </b-form-checkbox>

    <!-- GENERATE SELECTBOX -->
    <validation-provider :rules="item.rules || null" v-slot="validationContext">
      <b-form-group
          :label="item.label"
          v-if="item.type === 'select'"
          :class="item.class"
          :style="item.style"
          :disabled="item.isDisabled"
          :label-cols="typeof item['label-cols'] === 'number' ? item['label-cols'] : null"
          :label-cols-sm="typeof item['label-cols-sm'] === 'number' ? item['label-cols-sm'] : null"
          :label-cols-md="typeof item['label-cols-md'] === 'number' ? item['label-cols-md'] : null"
          :label-cols-lg="typeof item['label-cols-lg'] === 'number' ? item['label-cols-lg'] : null"
          :label-cols-xl="typeof item['label-cols-xl'] === 'number' ? item['label-cols-xl'] : null"
          :content-cols="typeof item['content-cols'] === 'number' ? item['content-cols'] : null"
          :content-cols-sm="typeof item['content-cols-sm'] === 'number' ? item['content-cols-sm'] : null"
          :content-cols-md="typeof item['content-cols-md'] === 'number' ? item['content-cols-md'] : null"
          :content-cols-lg="typeof item['content-cols-lg'] === 'number' ? item['content-cols-lg'] : null"
          :content-cols-xl="typeof item['content-cols-xl'] === 'number' ? item['content-cols-xl'] : null"
      >
        <b-form-select
            v-model="models[filterKey]"
            @change.native="item.change ? item.change(models[filterKey], getThis) : null;"
            :state="getValidationState(validationContext)"
            :disabled="item.isDisabled"
        >
          <b-form-select-option :key="index" v-for="(itemChild,index) in item.data" :value="typeof item.keyValue === 'string' ? itemChild[item.keyValue] : getSelectIdentValue(itemChild)">
            {{ typeof item.keyText === 'string' ? itemChild[item.keyText] : getSelectTextValue(itemChild) }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
    </validation-provider>

    <!-- GENERATE RADIO BUTTON -->
    <b-form-group
        v-if="item.type === 'radio'"
        :label="item.label"
        :class="item.class ? item.class : ''"
        :label-cols="typeof item['label-cols'] === 'number' ? item['label-cols'] : null"
        :label-cols-sm="typeof item['label-cols-sm'] === 'number' ? item['label-cols-sm'] : null"
        :label-cols-md="typeof item['label-cols-md'] === 'number' ? item['label-cols-md'] : null"
        :label-cols-lg="typeof item['label-cols-lg'] === 'number' ? item['label-cols-lg'] : null"
        :label-cols-xl="typeof item['label-cols-xl'] === 'number' ? item['label-cols-xl'] : null"
        :content-cols="typeof item['content-cols'] === 'number' ? item['content-cols'] : null"
        :content-cols-sm="typeof item['content-cols-sm'] === 'number' ? item['content-cols-sm'] : null"
        :content-cols-md="typeof item['content-cols-md'] === 'number' ? item['content-cols-md'] : null"
        :content-cols-lg="typeof item['content-cols-lg'] === 'number' ? item['content-cols-lg'] : null"
        :content-cols-xl="typeof item['content-cols-xl'] === 'number' ? item['content-cols-xl'] : null"
        :style="item.style"
        :disabled="item.isDisabled"
    >
      <b-input-group class="no-shadow">
        <template v-if="item.append" #append>
          <b-form-input
              v-model="models[item.append]"
              :placeholder="item.appendPlaceHolder || ''"
              @keypress.enter.exact="search()"
              @change="item.change ? item.change(models[item.append], getThis) : null;"
              style="width: 60px; position: relative; top: -8px"
          >
          </b-form-input>
        </template>
        <b-form-radio-group
            v-model="models[filterKey]"
            :name="filterKey"
            @change="item.change ? item.change(models[filterKey], getThis) : null;"
            :stacked="item.stacked"
        >
          <b-form-radio v-for="(itemData, index) in item.data" :key="index" :value="itemData.value">
            {{itemData.text}}
            <span v-if="itemData.appendHTML" v-html="itemData.appendHTML"></span>
          </b-form-radio>
        </b-form-radio-group>
      </b-input-group>

      <template v-if="item.appendOptions">
        <MultiSelectForm
            :defaultOptions="item.options"
            :trackBy="item.optionTrackBy"
            :label="item.optionLabel"
            v-model="models[item.optionModel]"
            placeholderText="Pick Response Status"
        >
          <template v-slot:multi-select__selected="{option}">
            <slot :name="'multi-select__selected(' + filterKey + ')'" :option="option">
              {{ option[item.optionTrackBy] }}
            </slot>
          </template>
          <template v-slot:multi-select__text="{option}">
            <slot :name="'multi-select__text(' + filterKey + ')'" :option="option">
              {{ option }}
            </slot>
          </template>
        </MultiSelectForm>
      </template>
    </b-form-group>

    <!-- DATE -->
    <b-form-group
        v-if="item.type === 'date'"
        :label="item.label"
        :class="item.class ? item.class : ''"
        :label-cols="typeof item['label-cols'] === 'number' ? item['label-cols'] : null"
        :label-cols-sm="typeof item['label-cols-sm'] === 'number' ? item['label-cols-sm'] : null"
        :label-cols-md="typeof item['label-cols-md'] === 'number' ? item['label-cols-md'] : null"
        :label-cols-lg="typeof item['label-cols-lg'] === 'number' ? item['label-cols-lg'] : null"
        :label-cols-xl="typeof item['label-cols-xl'] === 'number' ? item['label-cols-xl'] : null"
        :content-cols="typeof item['content-cols'] === 'number' ? item['content-cols'] : null"
        :content-cols-sm="typeof item['content-cols-sm'] === 'number' ? item['content-cols-sm'] : null"
        :content-cols-md="typeof item['content-cols-md'] === 'number' ? item['content-cols-md'] : null"
        :content-cols-lg="typeof item['content-cols-lg'] === 'number' ? item['content-cols-lg'] : null"
        :content-cols-xl="typeof item['content-cols-xl'] === 'number' ? item['content-cols-xl'] : null"
        :style="item.style"
    >
      <b-form-datepicker
          v-model="models[filterKey]"
          :reset-button="item.resetButton"
          @input="item.input ? item.input(models[filterKey], getThis) : null"
          :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
          locale="en"
      >
      </b-form-datepicker>
    </b-form-group>

    <!-- DATE RANGE -->
    <b-form-group
        v-if="item.type === 'daterange'"
        :label="item.label"
        :class="item.class ? item.class : ''"
        :label-cols="typeof item['label-cols'] === 'number' ? item['label-cols'] : null"
        :label-cols-sm="typeof item['label-cols-sm'] === 'number' ? item['label-cols-sm'] : null"
        :label-cols-md="typeof item['label-cols-md'] === 'number' ? item['label-cols-md'] : null"
        :label-cols-lg="typeof item['label-cols-lg'] === 'number' ? item['label-cols-lg'] : null"
        :label-cols-xl="typeof item['label-cols-xl'] === 'number' ? item['label-cols-xl'] : null"
        :content-cols="typeof item['content-cols'] === 'number' ? item['content-cols'] : null"
        :content-cols-sm="typeof item['content-cols-sm'] === 'number' ? item['content-cols-sm'] : null"
        :content-cols-md="typeof item['content-cols-md'] === 'number' ? item['content-cols-md'] : null"
        :content-cols-lg="typeof item['content-cols-lg'] === 'number' ? item['content-cols-lg'] : null"
        :content-cols-xl="typeof item['content-cols-xl'] === 'number' ? item['content-cols-xl'] : null"
        :style="item.style"
        :disabled="item.isDisabled"
        :key="'daterange-' + item.refresh_uuid"
    >
      <date-range
          v-model="models[filterKey]"
          :enableTime="item.enableTime || false"
          @change="item.change ? item.change(models[filterKey], getThis) : null;"
      ></date-range>
    </b-form-group>

    <!-- GENERATE TEXT AREA -->
    <b-form-group
        :class="item.class ? item.class : ''"
        v-if="item.type === 'text'"
        :label="item.label ? item.label: ''"
        :label-cols="typeof item['label-cols'] === 'number' ? item['label-cols'] : null"
        :label-cols-sm="typeof item['label-cols-sm'] === 'number' ? item['label-cols-sm'] : null"
        :label-cols-md="typeof item['label-cols-md'] === 'number' ? item['label-cols-md'] : null"
        :label-cols-lg="typeof item['label-cols-lg'] === 'number' ? item['label-cols-lg'] : null"
        :label-cols-xl="typeof item['label-cols-xl'] === 'number' ? item['label-cols-xl'] : null"
        :content-cols="typeof item['content-cols'] === 'number' ? item['content-cols'] : null"
        :content-cols-sm="typeof item['content-cols-sm'] === 'number' ? item['content-cols-sm'] : null"
        :content-cols-md="typeof item['content-cols-md'] === 'number' ? item['content-cols-md'] : null"
        :content-cols-lg="typeof item['content-cols-lg'] === 'number' ? item['content-cols-lg'] : null"
        :content-cols-xl="typeof item['content-cols-xl'] === 'number' ? item['content-cols-xl'] : null"
        :style="item.style"
    >
      <b-form-textarea
          v-model="models[filterKey]"
          :rows="item.rows || 5"
          :disabled="item.isDisabled"
          @change="item.change ? item.change(models[filterKey], getThis) : null;"
      ></b-form-textarea>
    </b-form-group>

    <!-- GENERATE FORM FILE -->
    <b-form-group
        :class="item.class ? item.class : ''"
        v-if="item.type === 'file'"
        :label="item.label ? item.label: ''"
        :label-cols="typeof item['label-cols'] === 'number' ? item['label-cols'] : null"
        :label-cols-sm="typeof item['label-cols-sm'] === 'number' ? item['label-cols-sm'] : null"
        :label-cols-md="typeof item['label-cols-md'] === 'number' ? item['label-cols-md'] : null"
        :label-cols-lg="typeof item['label-cols-lg'] === 'number' ? item['label-cols-lg'] : null"
        :label-cols-xl="typeof item['label-cols-xl'] === 'number' ? item['label-cols-xl'] : null"
        :content-cols="typeof item['content-cols'] === 'number' ? item['content-cols'] : null"
        :content-cols-sm="typeof item['content-cols-sm'] === 'number' ? item['content-cols-sm'] : null"
        :content-cols-md="typeof item['content-cols-md'] === 'number' ? item['content-cols-md'] : null"
        :content-cols-lg="typeof item['content-cols-lg'] === 'number' ? item['content-cols-lg'] : null"
        :content-cols-xl="typeof item['content-cols-xl'] === 'number' ? item['content-cols-xl'] : null"
        :style="item.style"
    >
      <b-form-file
          v-model="models[filterKey]"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
      >
      </b-form-file>
    </b-form-group>

    <!-- GENERATE FORM AUTO SUGGEST -->
    <b-form-group
        :class="item.class ? item.class : ''"
        v-if="item.type === 'auto-suggest'"
        :label="item.label ? item.label: ''"
        :label-cols="typeof item['label-cols'] === 'number' ? item['label-cols'] : null"
        :label-cols-sm="typeof item['label-cols-sm'] === 'number' ? item['label-cols-sm'] : null"
        :label-cols-md="typeof item['label-cols-md'] === 'number' ? item['label-cols-md'] : null"
        :label-cols-lg="typeof item['label-cols-lg'] === 'number' ? item['label-cols-lg'] : null"
        :label-cols-xl="typeof item['label-cols-xl'] === 'number' ? item['label-cols-xl'] : null"
        :content-cols="typeof item['content-cols'] === 'number' ? item['content-cols'] : null"
        :content-cols-sm="typeof item['content-cols-sm'] === 'number' ? item['content-cols-sm'] : null"
        :content-cols-md="typeof item['content-cols-md'] === 'number' ? item['content-cols-md'] : null"
        :content-cols-lg="typeof item['content-cols-lg'] === 'number' ? item['content-cols-lg'] : null"
        :content-cols-xl="typeof item['content-cols-xl'] === 'number' ? item['content-cols-xl'] : null"
        :style="item.style"
    >
      <vue-autosuggest
          :value="getAutoSuggestItemValue(item, filterSettings, filterKey) || item.value"
          :suggestions="suggestions[filterKey] || [{data: item.data}]"
          :input-props="{
            id: 'autosuggest__input',
            class: 'form-control',
            placeholder: item.placeHolder || '',
            disabled: item.isDisabled || false
          }"
          @focus="highlightSelection"
          :get-suggestion-value="(suggestion) => suggestion.item[item.keyText] || `(${suggestion.item[this.item.secondaryKeyText]})`"
          :section-configs="sectionConfigs(item.limit || false)"
          @selected="(data) => models[filterKey] = data.item[item.keyValue]"
          @input="item.customGenerateSuggestion ? item.customGenerateSuggestion($event, filterKey, getThis) : generateSuggestion($event, filterKey, item)"
          @item-changed="item.change ? item.change(models[filterKey], filterKey, getThis) : null;"
          :key="'autosuggest-' + item.refresh_uuid ? filterKey + '-' + item.refresh_uuid : filterKey"
      >
        <template slot-scope="{suggestion}">
          <div class="d-flex align-items-center">
            <slot :name="'autosuggest-item(' + filterKey + ')'" :item="suggestion.item">
              {{ suggestion.item[item.keyText] }}
            </slot>
          </div>
        </template>
      </vue-autosuggest>
    </b-form-group>

    <b-form-group
        :class="item.class ? item.class : ''"
        v-if="item.type === 'datetime'"
        :label="item.label ? item.label: ''"
        :label-cols="typeof item['label-cols'] === 'number' ? item['label-cols'] : null"
        :label-cols-sm="typeof item['label-cols-sm'] === 'number' ? item['label-cols-sm'] : null"
        :label-cols-md="typeof item['label-cols-md'] === 'number' ? item['label-cols-md'] : null"
        :label-cols-lg="typeof item['label-cols-lg'] === 'number' ? item['label-cols-lg'] : null"
        :label-cols-xl="typeof item['label-cols-xl'] === 'number' ? item['label-cols-xl'] : null"
        :content-cols="typeof item['content-cols'] === 'number' ? item['content-cols'] : null"
        :content-cols-sm="typeof item['content-cols-sm'] === 'number' ? item['content-cols-sm'] : null"
        :content-cols-md="typeof item['content-cols-md'] === 'number' ? item['content-cols-md'] : null"
        :content-cols-lg="typeof item['content-cols-lg'] === 'number' ? item['content-cols-lg'] : null"
        :content-cols-xl="typeof item['content-cols-xl'] === 'number' ? item['content-cols-xl'] : null"
        :style="item.style"
    >
      <flat-pickr
          :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:S' }"
          class="form-control"
          v-model="models[filterKey]"
          :placeholder="item.placeHolder"
      />
    </b-form-group>

    <!-- GENERATE MULTISELECT -->
    <b-form-group
        v-if="item.type === 'multiselect'"
        :label="item.label"
        :class="item.class ? item.class : ''"
        :label-cols="typeof item['label-cols'] === 'number' ? item['label-cols'] : null"
        :label-cols-sm="typeof item['label-cols-sm'] === 'number' ? item['label-cols-sm'] : null"
        :label-cols-md="typeof item['label-cols-md'] === 'number' ? item['label-cols-md'] : null"
        :label-cols-lg="typeof item['label-cols-lg'] === 'number' ? item['label-cols-lg'] : null"
        :label-cols-xl="typeof item['label-cols-xl'] === 'number' ? item['label-cols-xl'] : null"
        :content-cols="typeof item['content-cols'] === 'number' ? item['content-cols'] : null"
        :content-cols-sm="typeof item['content-cols-sm'] === 'number' ? item['content-cols-sm'] : null"
        :content-cols-md="typeof item['content-cols-md'] === 'number' ? item['content-cols-md'] : null"
        :content-cols-lg="typeof item['content-cols-lg'] === 'number' ? item['content-cols-lg'] : null"
        :content-cols-xl="typeof item['content-cols-xl'] === 'number' ? item['content-cols-xl'] : null"
        :style="item.style"
        :disabled="item.isDisabled"
    >
      <MultiSelectForm
          :defaultOptions="item.data"
          :trackBy="item.keyValue"
          v-model="multiSelectValue"
          :placeholderText="item.placeholder || 'Select items'"
          :labelSelectedText="item.selectedText || 'items selected'"
      >
        <template v-slot:multi-select__selected="{option}">
          <slot :name="'multi-select__selected(' + filterKey + ')'" :option="option">
            &nbsp;{{ option[item.keyText] }}
          </slot>
        </template>
        <template v-slot:multi-select__text="{option}">
          <slot :name="'multi-select__text(' + filterKey + ')'" :option="option">
            &nbsp;{{ option[item.keyText] }}
          </slot>
        </template>
      </MultiSelectForm>
    </b-form-group>
  </div>
</template>

<script>
import DateRange from './DateRange.vue'
import {VueAutosuggest} from 'vue-autosuggest'
import {ValidationProvider} from 'vee-validate'
import MultiSelectForm from '@/main/custom-components/MultiSelectForm.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    filterKey: {
      type: String,
      required: true
    },
    filterSettings: {
      type: Object,
      required: true
    },
    models: {
      type: Object,
      required: true
    },
    getThis: {
      type: Object,
      required: true
    },
    suggestions: {
      type: Object
    }
  },
  components: {
    MultiSelectForm,
    DateRange,
    VueAutosuggest,
    ValidationProvider,
    flatPickr
  },
  data () {
    return {
      multiSelectValue: []
    }
  },
  watch: {
    multiSelectValue (val) {
      if (val.length) {
        if (this.item.keyValue) {
          this.models[this.filterKey] = val.map(item => item[this.item.keyValue])
        } else {
          this.models[this.filterKey] = val
        }
      }
    }
  },
  methods: {
    search () {
      this.$emit('search')
    },
    getAutoSuggestItemValue (item, filterSettings, filterKey) {
      if (item.data && Array.isArray(item.data)) {
        const foundData = item.data.find(
          // eslint-disable-next-line eqeqeq
          data => data[item.keyValue] == filterSettings[filterKey].defaultValue || ''
        )

        // Only use defaultValue on first time init
        if (item.value === filterSettings[filterKey].defaultValue) {
          filterSettings[filterKey].defaultValue = ''
        }

        if (foundData && foundData[item.keyText]) {
          return foundData[item.keyText]
        }
      }

      return null
    },
    getValidationState ({ valid = null }) {
      if (!valid) return false
      return null
    },
    getSelectIdentValue (item) {
      if (typeof item.value !== 'undefined') return item.value
      return item.id
    },
    getSelectTextValue (item) {
      if (typeof item.text !== 'undefined') return item.text
      return item.name
    },
    resetSelectField (fieldName) {
      const firstItem = this.filterSettings[fieldName].data[0]
      this.models[fieldName] = this.getSelectIdentValue(firstItem)
      this.refreshLayoutOfField(fieldName)
    },
    refreshLayoutOfField (fieldName) {
      this.filterSettings[fieldName].refresh_uuid = (Math.random() + 1).toString(36).substring(7)
    },
    refreshLayoutOfFieldByKey (key, fieldName) {
      this.filterSettings[key][fieldName].refresh_uuid = (Math.random() + 1).toString(36).substring(7)
    },
    setValue (fieldName, value) {
      this.models[fieldName] = value
      this.refreshLayoutOfField(fieldName)
    },
    setValueByKey (key, fieldName, value) {
      this.models[fieldName] = value
      this.refreshLayoutOfFieldByKey(key, fieldName)
    },
    generateSuggestion (searchInput, key, item) {
      if (searchInput === '') {
        this.models[key] = ''
      }

      const filteredData = item.data
        .filter(data => this.calculateRelevance(data, searchInput, item) > 0)
        .sort((a, b) => this.calculateRelevance(b, searchInput, item) - this.calculateRelevance(a, searchInput, item))

      this.$set(this.suggestions, key, [{ data: filteredData }])
    },
    calculateRelevance (data, searchInput, item) {
      const valueKeys = item.findKeys ? item.findKeys.map(key => data[key] && (data[key].toString().toLowerCase() || '')).filter(key => key) : []

      let relevance = 0

      if (valueKeys.includes(searchInput.toLowerCase())) {
        relevance += 2
      }

      if (item.findKeys) {
        relevance += item.findKeys.some(key => data[key] && data[key].toString().toLowerCase()?.includes(searchInput.toLowerCase()))
      } else {
        const keyText = data[item.keyText].toString().toLowerCase() || ''

        relevance += keyText.includes(searchInput.toLowerCase())
      }

      return relevance
    },
    sectionConfigs (limit) {
      if (limit) {
        return {
          'default': {
            limit
          }
        }
      }
    },
    highlightSelection (e) {
      e.target.select()
    },
    filterData (data) {
      return data.filter(item => (item.onlyShowForIds ? item.onlyShowForIds.includes(this.$store.state.auth.AppActiveAdmin.id) : (item.show ?? true)))
    },
    getInitialOption (filterKey, itemData) {
      const selectedOption = itemData.options.find(x => this.models[filterKey].includes(x))
      return selectedOption || itemData.default_options
    },
    handleSelectChange (selectedValue, filterKey, itemData) {
      if (this.models[filterKey] && this.models[filterKey].length) {
        this.models[filterKey] = this.models[filterKey].filter(x => !itemData.options.includes(x))
      }

      this.models[filterKey].push(selectedValue)
      if (!this.models[filterKey].includes(itemData.value)) {
        this.models[filterKey].push(itemData.value)
        this.$forceUpdate()
      }
    }
  }
}
</script>

<style lang="scss">
  .reverse-label legend {
    order: 2
  }
  .no-label legend {
    display: none;
  }

  .custom-radio .custom-control-label {
    margin-left: 5px;
  }

  [dir] .input-group.no-shadow:not(.bootstrap-touchspin) {
    .input-group-append {
      margin-bottom: -15px;
    }

    &:focus-within {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  .select-options label.custom-control-label {
    &::before, &::after {
      margin-top: 6px !important;
    }
  }

  .form-row > div > .multiselect {
    margin-top: 0 !important;
  }
</style>
